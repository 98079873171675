var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"e-chat"},[_c('header',{staticClass:"e-chat__header"},[_c('div',{class:[
        'e-chat__title',
        {
          'chat-is-pending':
            _vm.currentUserChat && _vm.currentUserChat.status === 'pending'
        }
      ]},[_vm._v(" "+_vm._s(_vm.chatTitle)+" ")]),(_vm.currentUserChat)?_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.currentUserChat.status === 'active'
          ? _vm.$t('chat.close')
          : _vm.$t('chat.cancel'),"effect":"dark","placement":"top"}},[_c('icon-close',{staticClass:"e-chat__close",on:{"click":_vm.finishChat}})],1):_vm._e()],1),_c('div',{staticClass:"e-chat__content"},[_c('VuePerfectScrollbar',{ref:"scrollContainer"},[_c('div',{staticClass:"e-chat__content-inner"},_vm._l((_vm.messages),function(msg,index){return _c('chat-item',{key:index,attrs:{"type":msg.type,"messages":msg.messages}})}),1)]),_c('div',{staticClass:"e-chat__scroll-to-message e-btn e-btn--primary",class:{ 'e-chat__scroll-to-message--visible': _vm.notSeenMessage },on:{"click":function () {
          if (!_vm.notSeenMessage) {
            return
          }
          _vm.scrollToBottom()
        }}},[_c('div',{staticClass:"e-chat__scroll-to-message--icon"},[_c('icon-arrow-down')],1)]),(_vm.currentUserChat && _vm.typingState && !_vm.hideTypingState)?_c('the-chat-typing-indicator',{attrs:{"text":((_vm.currentUserChat.counselor) + "  schreibt")}}):_vm._e()],1),_c('footer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDisabled),expression:"!isDisabled"}],staticClass:"e-chat__footer"},[_c('autosize-textarea',{staticClass:"e-chat__input",attrs:{"placeholder":_vm.$t('chat.inputPlaceholder'),"name":"chatTextMessage","cols":"10","rows":"1"},nativeOn:{"input":function($event){return (function (ev) { return (_vm.charCount = _vm.chatTextMessage.length); })($event)},"keydown":[function($event){return _vm.startTypingStatus($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeyupEnter($event)}]},model:{value:(_vm.chatTextMessage),callback:function ($$v) {_vm.chatTextMessage=$$v},expression:"chatTextMessage"}}),_c('div',{staticClass:"e-chat__submit"},[_c('button',{staticClass:"e-btn e-btn--primary",class:{ 'is-disabled': !_vm.canSend },attrs:{"disabled":!_vm.canSend,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitMessage($event)}}},[_vm._v(" "+_vm._s(_vm.$t('chat.submit'))+" ")])]),_c('div',{staticClass:"e-dialog__charcount",class:{
        'e-dialog__charcount--alert': _vm.maxCharsExceeded
      }},[_vm._v(" "+_vm._s(_vm.charCountString)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }