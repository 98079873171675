<template>
  <div class="e-chat__message--typing">
    {{ text }}&nbsp;
    <span />
    <span />
    <span />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/TheChatTypingIndicator';
</style>
